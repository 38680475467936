import React from "react"
import { Button } from "antd"
import { navigate } from "gatsby"

const Comparison = props => {
  const scrollToDiv = id => {
    const el = document.getElementById(id)
    window.scroll({ top: el.offsetTop, behavior: "smooth" })
  }
  return (
    <div className="bg-gray" id="how-it-works">
      <div className="section-content pb-20">
        <div className="w-full flex flex-col justify-center">
          <div className="container mx-auto text-center p-10">
            <div className="section-title color-home" style={{ fontSize: "40px" }}>How to Boost with Boostik</div>
            <div className="section-text color-home">
              Our experience speaks for us. More than +800k artists already trust
              us.
            </div>
          </div>
          <div className="container mx-auto text-center">
            <div className="flex justify-center items-center flex-col lg:flex-row">
              <div className="how-container">
                <div className="step-img">
                  <img src="/img/frame1.png" />
                </div>
                <div>
                  <img src="/img/icon1.png" />
                </div>
                <div>
                  <h5>Choose a <br/> plan for you</h5>
                  <p>Select the plan that best suits your needs. Without any commitment of permanence.</p>

                </div>
              </div>
              <div className="how-container">
                <div className="step-img">
                  <img src="/img/frame2.png" />
                </div>
                <div>
                  <img src="/img/icon2.png" />
                </div>
                <div>
                  <h5>Customize <br/> campaign</h5>
                  <p>Customize your campaign, choose more options to get powerfully boost.</p>

                </div>
              </div>
              <div className="how-container">
                <div className="step-img">
                  <img src="/img/frame3.png" />
                </div>
                <div>
                  <img src="/img/icon3.png" />
                </div>
                <div>
                  <h5>We receive <br/> your order</h5>
                  <p>When we receive the account you want to promote, we get to work and the process begins!</p>
                </div>
              </div>
              <div className="how-container">
                <div className="step-img">
                  <img src="/img/frame4.png" />
                </div>
                <div>
                  <img src="/img/icon4.png" />
                </div>
                <div>
                  <h5>Enjoy the <br/> results!</h5>
                  <p>Watch your profile grow. You will see the results in a short time. It has never been so easy.</p>
                </div>
              </div>
            </div>
            <Button
              type="primary"
              shape="round"
              style={{ height: "40px", width: "300px" }}
              className={`background-color-header-button-home background-color-home home-button color-home`}
              onClick={() => {
                return document.getElementById("pricing")
                  ? scrollToDiv("pricing")
                  : navigate("/")
              }}
            >
              Boost your TikTok Today! <img style={{ display: "inline", marginLeft: "3px" }}
                                                src="/img/RocketLaunch.png" />

            </Button>
          </div>

        </div>
      </div>
    </div>

  )
}

export default Comparison
