import React from "react"
import { Rate } from "antd"

const CustomerRating = props => {
  return (
    <div className="section-content" style={{paddingBottom: '30px'}}>
      <div className="container mx-auto text-center">
        <div className={`section-title color-${props.categoryName}`} style={{fontSize: "40px", color: "rgb(2, 6, 23)"}}>
          Hear From Our Happy Boosters
        </div>
        <div className={`section-text color-${props.categoryName}`}>
          <Rate
            allowHalf
            disabled
            defaultValue={4.5}
            className={`color-${props.categoryName}`}
          />
        </div>
        <div className="text-gray-400">4.58 out of 5 stars (32 reviews)</div>
      </div>
    </div>
  )
}

export default CustomerRating
