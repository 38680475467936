/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { handleGetProductsList } from "../../data/usecases/getproductslist"
import { handleGetCartIdFromCookie } from "../../data/usecases/getcartidfromcookie"
import { handleCreateCart } from "../../data/usecases/createnewcart"
import { handleResetExistingCart } from "../../data/usecases/resetexisitngcart"
import { handleAddItemsToCart } from "../../data/usecases/additemstocart"
import { Button, notification } from "antd"
import { navigate } from "gatsby"
import { currencyToSymbol } from "../../data/managers/currencytosymbol"
import { extractPackage } from "../../data/managers/extract-package"

const getIcon = category => {
  if (category === "tiktok") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2859 3333"
        width="21px"
        height="21px"
        fill="#4fb5c0"
        style={{ marginRight: "5px" }}
      >
        <path
          d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"></path>
      </svg>
    )
  }
  if (category === "instagram") {
    return <i className={`bx bxl-${category} text-2xl`} style={{ marginRight: "5px" }}></i>
  }
  if (category === "soundcloud") {
    return <i className={`bx bxl-${category} text-2xl`} style={{ marginRight: "5px" }}></i>
  }
}

const Pricing = props => {
  const [existingCartId, setExistingCartId] = useState(null)
  const [pricesList, setPricesList] = useState([])
  const [selectedProductPriceInfo, setSelectedProductPriceInfo] = useState([])
  const [startItem, setStartItem] = useState(0)
  const [lastItem, setLastItem] = useState(4)
  const sendNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      top: 100
    })
  }

  const goToCheckoutPage = () => navigate(`/checkout`)

  const addItemToCart = itemReference => {
    handleAddItemsToCart(existingCartId, [itemReference], (error, message) => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      goToCheckoutPage()
    })
  }

  const resetExistingCart = itemReference => {
    handleResetExistingCart(existingCartId, error => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      addItemToCart(itemReference)
    })
  }

  const createNewCartAndAddItem = itemReference => {
    handleCreateCart(itemReference, (error, cartInfo) => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      document.cookie = `cart_id=${cartInfo.cartId}; path=/`
      setExistingCartId(cartInfo.cartId)
      goToCheckoutPage()
    })
  }

  const buyNowAction = itemReference => {
    return !existingCartId
      ? createNewCartAndAddItem(itemReference)
      : resetExistingCart(itemReference)
  }

  const getProductInfoAndSettedAsSelected = () => {
    let currentPricesList = pricesList
    handleGetProductsList(
      props.categoryName,
      props.productName,
      (error, productsList) => {
        if (error) {
          return alert(error)
        }
        currentPricesList[
          `${props.categoryName}_${props.productName}`
          ] = productsList
        setPricesList(currentPricesList)
        setSelectedProductPriceInfo(productsList)
      }
    )
  }

  useEffect(() => {
    if (pricesList[`${props.categoryName}_${props.productName}`]) {
      setSelectedProductPriceInfo(
        pricesList[`${props.categoryName}_${props.productName}`]
      )
    } else {
      getProductInfoAndSettedAsSelected()
    }
  }, [props.productName])

  useEffect(() => {
    if (selectedProductPriceInfo.length > 4) {
      setStartItem(0)
      setLastItem(4)
    }
  }, [selectedProductPriceInfo])

  useEffect(() => {
    setExistingCartId(handleGetCartIdFromCookie())
  }, [])

  useEffect(() => {
    const container = document.querySelector('.card-container');
    let isDragging = false;
    let startX;
    let scrollLeft;
    container.addEventListener('mousedown', (e) => {
      isDragging = true;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
      container.style.cursor = 'grabbing';
    });
    container.addEventListener('mouseleave', () => {
      isDragging = false;
      container.style.cursor = 'move';
    });

    container.addEventListener('mouseup', () => {
      isDragging = false;
      container.style.cursor = 'move';
    });
    container.addEventListener('mousemove', (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 2;
      container.scrollLeft = scrollLeft - walk;
    });
  }, [])

  return (
    <div>

      <div className="container mx-auto" style={{marginBottom: "40px", width: "100%", maxWidth: "100%"}}>

        <div className="flex card-container">
          <div className="pricing-card max960">
          </div>
          <div className="pricing-card max1600">
          </div>

          {selectedProductPriceInfo && selectedProductPriceInfo.length > 0 ? (
            [...selectedProductPriceInfo]
              .map((item, index) => (
                <div key={index} className="pricing-card product-shadow">
                  <div>
                    <div
                      className={
                        `pricing-card-subtitle`
                      }
                    >
                      <p className="flex items-center space-x-2 ptitle">
                        {item.times}
                      </p>
                    </div>
                  </div>
                  <div className="pricing-card-content">
                    <ul>
                      <li><i className="fa fa-user-plus"></i><p>Quality {item.subCategory}</p></li>
                    <li><i className="fa fa-credit-card"></i><p>One single payment</p></li>
                    <li><i className="fa fa-clock"></i><p>Instant start</p></li>
                    <li><i className="fa fa-lock"></i><p>No password required</p></li>
                    <li><i className="fa fa-tag"></i><p>30% discount</p></li>
                    </ul>
                  </div>
                  <div
                    className={`pricing-card-header`}
                  >
                    <div className="pricing-card-title">
                      {currencyToSymbol(item.currency)}
                      {item.amount}
                      <p
                        className="text-sm font-weight-light line-through"
                        style={{
                          fontSize: "14px",
                          color: "#FE2C55",
                          marginBottom: "8px",
                          display: "inline",
                          marginLeft: "5px"
                        }}
                      >
                        {currencyToSymbol(item.currency)}
                        {Math.round(item.amount * 1.3)}
                      </p>
                    </div>

                  </div>
                  <Button
                    type="tiktok"
                    shape="round"
                    style={{ marginTop: "15px" }}
                    className={`header-section-button background-color-${
                      props.categoryName
                    } ${
                      item.popular ? "header-section-button-recomended" : ""
                    }`}
                    onClick={() => buyNowAction(item.sku)}
                  >
                      <span>
                        Purchase
                      </span>
                  </Button>
                </div>
              ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Pricing
