import React, { useState } from "react"
import User1 from "./../../static/img/users/1.png"
import User2 from "./../../static/img/users/2.png"
import User3 from "./../../static/img/users/3.png"
import User4 from "./../../static/img/users/4.png"
import User5 from "./../../static/img/users/5.png"
import User6 from "./../../static/img/users/6.png"
import User7 from "./../../static/img/users/7.png"
import User8 from "./../../static/img/users/8.png"
import User9 from "./../../static/img/users/9.png"
import User10 from "./../../static/img/users/10.png"
import User11 from "./../../static/img/users/11.png"
import User12 from "./../../static/img/users/12.png"
import User13 from "./../../static/img/users/13.png"
import User14 from "./../../static/img/users/14.png"
import User15 from "./../../static/img/users/15.png"
import User16 from "./../../static/img/users/16.png"
import User17 from "./../../static/img/users/17.png"
import User18 from "./../../static/img/users/18.png"
import User19 from "./../../static/img/users/19.png"
import User20 from "./../../static/img/users/20.png"
import User21 from "./../../static/img/users/21.png"
import { Rate } from "antd"

const Testimonials = props => {
  const [startItem, setStartItem] = useState(0)
  const [lastItem, setLastItem] = useState(3)

  const getUserImage = user => {
    switch (user) {
      case "user1":
        return <img src={User1} className="image-testimonial" />
      case "user2":
        return <img src={User2} className="image-testimonial" />
      case "user3":
        return <img src={User3} className="image-testimonial" />
      case "user4":
        return <img src={User4} className="image-testimonial" />
      case "user5":
        return <img src={User5} className="image-testimonial" />
      case "user6":
        return <img src={User6} className="image-testimonial" />
      case "user7":
        return <img src={User7} className="image-testimonial" />
      case "user8":
        return <img src={User8} className="image-testimonial" />
      case "user9":
        return <img src={User9} className="image-testimonial" />
      case "user10":
        return <img src={User10} className="image-testimonial" />
      case "user11":
        return <img src={User11} className="image-testimonial" />
      case "user12":
        return <img src={User12} className="image-testimonial" />
      case "user13":
        return <img src={User13} className="image-testimonial" />
      case "user14":
        return <img src={User14} className="image-testimonial" />
      case "user15":
        return <img src={User15} className="image-testimonial" />
      case "user16":
        return <img src={User16} className="image-testimonial" />
      case "user17":
        return <img src={User17} className="image-testimonial" />
      case "user18":
        return <img src={User18} className="image-testimonial" />
      case "user19":
        return <img src={User19} className="image-testimonial" />
      case "user20":
        return <img src={User20} className="image-testimonial" />
      case "user21":
        return <img src={User21} className="image-testimonial" />
    }
  }

  return (
    <div>
      <div className="hidden md:inline">
        <div className="container mx-auto ">
          <div className="flex justify-center items-center">

              <div>
                <button
                  onClick={() => {
                    if(startItem !== 0) {
                      const start = startItem
                      const last = lastItem
                      setStartItem(start - 1)
                      setLastItem(last - 1)
                    }
                  }}
                >
                  <i
                    className={`bx bx-left-arrow text-3xl color-${props.categoryName}`}
                  ></i>
                </button>
              </div>

            {props.testimonialsList && props.testimonialsList.length > 0 ? (
              [...props.testimonialsList]
                .slice(startItem, lastItem)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`text-left mx-4 px-12 py-10  rounded-md review-color review-color-${index}`}
                    style={{ width: "360px" }}
                  >
                    <div>
                      {getUserImage(item.userImage)}
                      <div
                        className={`my-2 text-xl color-white`}
                      >
                        {item.name}{" "}
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "#09AFE3", fontSize: "12px" }}
                        ></i>
                      </div>
                      <Rate
                        allowHalf
                        disabled
                        value={item.review}
                        className={`color-${props.categoryName}`}
                        style={{ fontSize: "12px" }}
                      />
                      <div className="my-2 color-white review-comment">{item.comment}</div>
                    </div>
                  </div>
                ))
            ) : (
              <div></div>
            )}

                <div>
                  <button
                    onClick={() => {
                      if(lastItem !== props.testimonialsList.length &&
                        props.testimonialsList.length > 3) {
                        const start = startItem
                        const last = lastItem
                        setStartItem(start + 1)
                        setLastItem(last + 1)
                      }
                    }}
                  >
                    <i
                      className={`bx bx-right-arrow text-3xl color-${props.categoryName}`}
                    ></i>
                  </button>
                </div>

          </div>
        </div>
      </div>
      <div className="inline md:hidden">
        <div className="container mx-auto ">
          <div className="flex items-center flex-col">
            {props.testimonialsList && props.testimonialsList.length > 0 ? (
              props.testimonialsList.map((item, index) => (
                index > 2 && (
                  <div
                    key={index}
                    className="text-left mt-4 px-12 py-10 border-2 border-gray-100 rounded-lg shadow-lg review-mobile"
                    style={{ height: "300px", width: "320px", background: '#0F172A' }}
                  >
                    <div>
                      {getUserImage(item.userImage)}
                      <div className={`my-2 text-xl color-white`}>
                        {item.name}{" "}
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "#09AFE3", fontSize: "12px" }}
                        ></i>
                      </div>
                      <Rate
                        allowHalf
                        disabled
                        value={item.review}
                        className={`color-${props.categoryName}`}
                        style={{ fontSize: "12px" }}
                      />
                      <div className="my-2 text-white">{item.comment}</div>
                    </div>
                  </div>
                )
              ))
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
