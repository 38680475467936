import React from "react"

const Features = props => {
  return (
    <div className="text-left mt-5" id="features">
      <div className="flex flex-wrap">
        <div className={`section-title color-${props.categoryName}`} style={{ fontSize: "40px" }}>
          <div>What makes us stand <br /> out from the rest?</div>
        </div>
        <div className={`section-text color-${props.categoryName}`} style={{textAlign: "left"}}>
          <div>We constantly strive to provide you with the best service: hassle-free, 24/7 customer support and
            reasonable prices.
          </div>
        </div>
      </div>
      <div className={`section-text color-${props.categoryName}`} style={{ marginTop: "0" }}>
        <div className="section-content" style={{padding:'0'}}>
          <div className="flex justify-center flex-col items-center md:flex-row features-content">
            <div
              className={`features-menu-button -selected color-${props.categoryName} mt-5 feature`}
            >
              <img src="/img/f3.png" className="feature-img feature-img-1" />
              <div className="feature-video">
                <video width="270" height="400" autoPlay loop muted="true" preload tabIndex="-1" playsInline disableRemotePlayback>
                  <source src="/video/v2.mp4" type="video/mp4" />
                  <source src="/video/v2.webm" type="video/webm" />
                </video>
              </div>
              <div className="mt-5 font-bold feature-title" >Quick Delivery</div>
              <div className="text-base">
                Orders are automatically submitted to our system and will be
                processed instantly.
              </div>
            </div>
            <div
              className={`features-menu-button  color-${props.categoryName} mt-5  feature`}
            >
              <img src="/img/f2.png" className="feature-img feature-img-2" />
              <div className="feature-video">

                <video width="270" height="400" autoPlay loop muted="true" preload tabIndex="-1" playsInline disableRemotePlayback>
                  <source src="/video/v1.mp4" type="video/mp4" />
                  <source src="/video/v1.webm" type="video/webm" />
                </video>
              </div>
              <div className="mt-5 font-bold feature-title">Trust & Safety</div>
              <div className="text-base">
                We will never ask for your password or any other sensitive data
                in order to provide our services.
              </div>
            </div>
            <div
              className={`features-menu-button  color-${props.categoryName} mt-5  feature`}
            >
              <img src="/img/f1.png" className=" feature-img feature-img-3" />
              <div className="feature-video">
                <video width="270" height="400" autoPlay loop muted="true" preload tabIndex="-1" playsInline disableRemotePlayback>
                  <source src="/video/v3.mp4" type="video/mp4" />
                  <source src="/video/v3.webm" type="video/webm" />
                </video>
              </div>
              <div className="mt-5 font-bold feature-title">Ease of use</div>
              <div className="text-base">
                Our added value is how simple and easy it’s to use our services.
                See for yourself!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
